/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Form } from 'antd'
import { IconLock } from '../../assets/icons/index'
import { routes } from '../../App'
import { injectIntl } from 'react-intl'
import BasicButton from 'components/Basic/BasicButton/BasicButton'
import { IconWarning } from 'assets/icons/iconBasic'
import useCommonHook from 'hooks/useCommonHook'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { IconUserCircle } from 'assets/icons/iconBasic'
import BasicInput from 'components/Basic/BasicInput/BasicInput'
import useLogin from '../../actionsFeatures/actionsLogin'

function LoginComponent() {
  const [user, setUser] = useState({
    username: '',
    password: ''
  })
  const { handleLogin, errorsSubmit, setErrorsSubmit } = useLogin()
  const auth = useSelector((state) => (state.member ? state.member : {}))
  const history = useHistory()
  const { translate } = useCommonHook()
  const [form] = Form.useForm()

  useEffect(() => {
    window.localStorage.removeItem('data')
    window.localStorage.removeItem('token')
    window.localStorage.removeItem('isUserLoggedIn')
  }, [])

  useEffect(() => {
    if (auth.isUserLoggedIn) {
      history.push('/')
    }
    const remove = localStorage.getItem('remember')
    if (form && remove) {
      const username = localStorage.getItem('username')
      const password = localStorage.getItem('password')
      setUser({
        username,
        password
      })
      form.setFieldsValue({
        username,
        password
      })
    }
  }, [])

  return (
    <div>
      <div className="login-center">
        <Form
          name="login"
          autoComplete="off"
          form={form}
          onFinish={(values) => {
            handleLogin(values)
          }}>
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: translate('Please_enter_account_email')
              }
            ]}>
            <div>
              <BasicInput
                className="login-icon"
                placeholder={translate('account') + "/ " + translate('email')}
                onChange={(e) => {
                  setUser({ ...user, username: e?.target?.value })
                  setErrorsSubmit(false)
                }}
                // onBlur={() => checkRegexEmailText(user.username)}
                type="text"
                prefix={<IconUserCircle width={24} height={24} />}
                size="large"
                value={user.username}
              />
            </div>
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: translate('password_required')
              }
            ]}>
            <div>
              <BasicInput
                className="login-icon"
                type="password"
                onChange={(e) => {
                  setUser({ ...user, password: e?.target?.value })
                  setErrorsSubmit(false)
                }}
                prefix={<IconLock width={24} height={24} />}
                placeholder={translate('password')}
                size="large"
                value={user.password}
              />
            </div>
          </Form.Item>

          {errorsSubmit &&
            <>
              <div className="error-login">
                <IconWarning />
                <div className="error-login-text">
                  {translate('If_you_enter_incorrectly_more_than_5_times_your_login_will_be_restricted')}
                </div>
              </div>
              <div className="error-login mt-3">
                <IconWarning />
                <div className="error-login-text">
                  {translate('Account_Email_or_password_is_incorrect_Please_check_again')}
                </div>
              </div>
            </>
          }

          <div className="w-100 d-flex justify-content-center login-btn mt-3">
            <BasicButton
              htmlType="submit"
              size="large"
              color="success"
            >
              {translate('login')}
            </BasicButton>
          </div>

        </Form>
        <div className='ButtonDirect'>
          <BasicButton
            color="success"
            outlined={true}
            onClick={() => {
              history.push(routes.register.path)
            }}
          >
            {translate('register')}
          </BasicButton>
          <BasicButton
            className=''
            color="danger"
            outlined={true}
            onClick={() => {
              history.push(routes.forgotPass.path)
            }}
          >
            {translate('forgot')}
          </BasicButton>
        </div>
      </div>
    </div>
  )
}
export default injectIntl(LoginComponent)
