import { handleUpdateDetail } from 'actions/userAction'
import { Form } from 'antd'
import { routes } from 'App'
import _ from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import AppUsersAppUsers from 'services/apppUsers'
import PaymentMethod from 'services/paymentMethod'

export const WALLET = Object.freeze({
  POINT: 'PointWallet', // Ví chính
  BONUS_BET: 'BonusBetWallet',//Ví hoa hồng chơi
  BONUS_SALE: 'BonusSaleWallet', //Ví hoa hồng bảo hiểm
  EXPERT_LEAD_WALLET: 'EXPERT_LEAD_WALLET', // Ví lợi nhuận" // Ví lợi nhuận sao chép lệnh (Ví nhận lợi nhuận sao chép lệnh của hội viên )
  EXPERT_FUND_WALLET: 'EXPERT_FUND_WALLET', // "Ví ký quỹ" // Ví quỹ sao chép lệnh (Ví chuyên gia ký quỹ)
  USER_PLAY_WALLET: 'USER_PLAY_WALLET', // "Ví chơi" // Ví tiền chơi sao chép lệnh (Ví hội viên ký quỹ)
})

export const WALLET_RECORD_TYPE = {
  REFER_BONUS: 'REFER_BONUS', // hoa hồng
  REFER_BONUS_SALE: 'REFER_BONUS_SALE', // hoa hồng thuê máy
  REFER_BONUS_BET: 'REFER_BONUS_BET', // hoa hồng khai thác
  EVENT_BONUS: 'EVENT_BONUS', // thưởng do tham gia 1 hoạt động gì đó (giới thiệu, sự kiện ..v.v)
  ADMIN_BONUS: 'ADMIN_BONUS', // admin thưởng vi lý do gì đó
  EARNED: 'EARNED', // đào được, khai thác được, kiếm được,
  ADMIN_ADJUST: 'ADMIN_ADJUST', //Admin điều chỉnh
  PAYMENT_DEPOSIT: 'PAYMENT_DEPOSIT', //User nạp tiền PIT hoặc USDT
  PAYMENT_WITHDRAW: 'PAYMENT_WITHDRAW', //User rút tiền PIT hoặc USDT
  PAYMENT_DEPOSIT_BANK: 'PAYMENT_DEPOSIT_BANK', //User nạp tiền PIT hoặc USDT
  PAYMENT_WITHDRAW_BANK: 'PAYMENT_WITHDRAW_BANK', //User rút tiền PIT hoặc USDT
  PAYMENT_EXCHANGE: 'PAYMENT_EXCHANGE', //User đổi tiền
  EXTERNAL_DEPOSIT: 'EXTERNAL_DEPOSIT', //User nạp tiền vào game
  EXTERNAL_WITHDRAW: 'EXTERNAL_WITHDRAW', //User rut tiền tu game
  EXTERNAL_WITHDRAW_REFUND: 'EXTERNAL_WITHDRAW_REFUND', //User nap tien game bi loi, refund lai
  SAVING: 'SAVING', // lãi qua đêm
  STAKING_COMPLETE: 'STAKING_COMPLETE', // Hoàn trả số PIT gốc khi gửi staking,
  STAKING_EARNED: 'STAKING_EARNED', // Trả lãi suất gửi staking,
  BUY_SERVICE_PACKAGE: 'BUY_SERVICE_PACKAGE', // Thuê máy
  TRANSFER_ADDITION_FROM_USER: 'TRANSFER_ADDITION_FROM_USER', // Cộng tiền cho người chuyển nhượng khi chuyển nhượng thành công
  TRANSFER_ADDITION_SUBTRACT_TO_USER: 'TRANSFER_ADDITION_SUBTRACT_TO_USER', // Trừ tiền ở người nhận máy chuyển nhượng
  REFUND_DISPOSES_USER_PACKAGE: 'REFUND_DISPOSES_USER_PACKAGE', // Hoàn trả khi người dùng thanh lý máy
  REFUND_ADMIN_RECALL_PACKAGE: 'REFUND_ADMIN_RECALL_PACKAGE' // Hoàn trả khi admin thu hồi máy
}

export const isPointWallet = (wallet) => wallet.walletType === WALLET.POINT
export const isBonusBetWallet = (wallet) => wallet.walletType === WALLET.BONUS_BET
export const isExpertLeadWallet = (wallet) => wallet.walletType === WALLET.EXPERT_LEAD_WALLET
export const isExpertFundWallet = (wallet) => wallet.walletType === WALLET.EXPERT_FUND_WALLET
export const isUserPlayWallet = (wallet) => wallet.walletType === WALLET.USER_PLAY_WALLET

export const ACTION = Object.freeze({
  requestDeposit: 1,
  requestWithdrawUSDT: 2,
  ExchangeFAC: 3,
  ExchangePOINT: 4,
  requestWithdrawBTC: 5,
  requestReceiveBTC: 6
})

export const isLeftAction = (action) => [ACTION.requestDeposit, ACTION.ExchangeFAC, ACTION.ExchangePOINT, ACTION.requestWithdrawBTC].includes(action)
export const isRightAction = (action) => [ACTION.requestWithdrawUSDT, ACTION.requestReceiveBTC].includes(action)
export const isRequestReceiveBTC = (action) => [ACTION.requestReceiveBTC].includes(action)
export const isRequestWithdrawBtc = (action) => [ACTION.isRequestWithdrawBTC].includes(action)
export const isRequestBTC = (action) => [ACTION.requestWithdrawBTC, ACTION.requestReceiveBTC].includes(action)
export const isRequestDeposit = (action) => [ACTION.requestDeposit].includes(action)
export const isRequestWithdrawUsdt = (action) => [ACTION.requestWithdrawUSDT].includes(action)
export const isRequestUsdt = (action) => [ACTION.requestDeposit, ACTION.requestWithdrawUSDT].includes(action)
export const isExchangeAction = (action) => [ACTION.ExchangeFAC, ACTION.ExchangePOINT].includes(action)
export const isExchangeFAC = (action) => [ACTION.ExchangeFAC].includes(action)

export const useManagement = () => {
  const [activeWallet, setActiveWallet] = useState({})
  const dispatch = useDispatch()
  const [action, setAction] = useState(ACTION.requestDeposit)
  const [paymentMethods, stePaymentMethods] = useState([])
  const intl = useIntl()
  const t = useCallback((id) => intl.formatMessage({ id }), [intl])
  const selectWallets = (state) => state.member?.wallets
  const walletSelector = useMemo(() => {
    return createSelector(selectWallets, (wallets) =>
      wallets.map((wallet) => {
        const w = _.pick(wallet, ['walletId', 'walletType', 'balance'])
        const CMS = {
          [WALLET.USDT]: {
            title: t('usdt_wallet_detail'),
            navigate: {
              left: t('deposit_usdt'),
              right: t('withdraw_usdt')
            },
            input: {
              label: t('deposit_amount_2'),
              required: t('deposit_amount_required_2'),
              placeholder: t('deposit_amount_placeholder_2')
            },
            code: 'USDT',
            sendBtnText: t('confirm'),
            receiveBtnText: t('confirm'),
            viewDepositHistory: t('view_deposit_usdt_history'),
            viewWithdrawHistory: t('view_withdraw_usdt_history'),
            viewDeposit: routes.managementDepositHistory.path,
            viewWithdraw: routes.managementWithdrawHistoryUSDT.path
          },
          [WALLET.BTC]: {
            title: t('btc_wallet_detail'),
            navigate: {
              left: t('withdraw_btc'),
              right: t('receive_btc')
            },
            code: 'BTC',
            input: {
              label: t('withdraw_amount'),
              required: t('withdraw_amount_required'),
              placeholder: t('withdraw_amount_placeholder')
            },
            sendBtnText: t('confirm'),
            receiveNote: t('receive_note'),
            receiveBtnText: t('increase_performance_btc'),
            viewDepositHistory: t('view_withdraw_btc'),
            viewWithdrawHistory: t('view_receive_btc'),
            viewWithdraw: routes.managementViewHistoryBTC.path,
            viewDeposit: routes.managementWithdrawHistoryBTC.path
          },
          [WALLET.FAC]: {
            title: t('fac_wallet_detail'),
            description: t('fac_detail'),
            navigate: {
              left: t('exchange')
            },
            input: {
              label: t('fac_exchange_amount'),
              required: t('exchange_amount_required'),
              placeholder: t('exchange_amount_placeholder')
            },
            code: 'Fi',
            sendBtnText: t('exchange_2'),
            exchangeNote: t('fac_note'),
            viewDepositHistory: t('view_exchange_history'),
            viewWithdrawHistory: t('view_receive_fac'),
            viewDeposit: routes.managementUserExchangeFACHistory.path,
            viewWithdraw: routes.managementViewHistoryFAC.path
          },
          [WALLET.POINT]: {
            title: t('point_wallet_detail'),
            description: t('point_detail'),
            navigate: {
              left: t('exchange_to_fac')
            },
            input: {
              label: t('exchange_amount'),
              required: t('exchange_amount_required'),
              placeholder: t('exchange_amount_placeholder')
            },
            code: 'Fi',
            sendBtnText: t('exchange_2'),
            exchangeNote: t('fac_not_3'),
            viewDepositHistory: t('view_exchange_history'),
            viewWithdrawHistory: t('view_receive_point'),
            viewWithdraw: routes.managementUserReceivePOINTHistory.path,
            viewDeposit: routes.managementUserExchangePOINTHistory.path
          }
        }
        return Object.assign(w, CMS[w.walletType])
      })
    )
  }, [t])
  const wallets = useSelector(walletSelector)
  const appUserId = useSelector((state) => state.member?.appUserId)
  const { diachiviUSDT, diachiviBTC } = useSelector((state) => state.member)
  const [form] = Form.useForm()

  const getDetailUserById = (appUserId) => {
    AppUsersAppUsers.getDetailUserById({
      id: appUserId
    }).then((result) => {
      const { isSuccess, data } = result
      if (isSuccess) {
        dispatch(handleUpdateDetail(data))
      }
    })
  }

  useEffect(() => {
    if (wallets?.length) {
      const pointWallet = _.find(wallets, { walletType: WALLET.POINT })
      setActiveWallet(pointWallet)
    }
  }, [wallets])
  useEffect(() => {
    PaymentMethod.getList().then((result) => {
      if (result.isSuccess) {
        stePaymentMethods(result.data)
      }
    })
  }, [])
  const setActiveWalletByType = (walletType) => {
    switch (walletType) {
      case WALLET.USDT:
        setAction(ACTION.requestDeposit)
        break
      case WALLET.FAC:
        setAction(ACTION.ExchangeFAC)
        break
      case WALLET.BTC:
        setAction(ACTION.requestWithdrawBTC)
        break
      case WALLET.POINT:
        setAction(ACTION.ExchangePOINT)
        break
      default:
        break
    }
    const w = _.find(wallets, { walletType })
    setActiveWallet(w)
    form.resetFields()
  }
  /**
   * Set action based on direction and active wallet type
   *
   * @param {*} direction true if left/false otherwise
   */
  const setActionByDirection = (direction) => {
    switch (activeWallet.walletType) {
      case WALLET.USDT:
        setAction(direction ? ACTION.requestDeposit : ACTION.requestWithdrawUSDT)
        break
      case WALLET.FAC:
        setAction(ACTION.ExchangeFAC)
        break
      case WALLET.BTC:
        setAction(direction ? ACTION.requestWithdrawBTC : ACTION.requestReceiveBTC)
        break
      case WALLET.POINT:
        setAction(ACTION.ExchangePOINT)
        break
      default:
        break
    }
  }

  const handleExchangeFromChange = (e) => {
    form.setFieldsValue({ paymentToAmount: e.target.value })
  }

  const handleExchangeToChange = (e) => {
    form.setFieldsValue({ paymentAmount: e.target.value })
  }

  return {
    activeWallet,
    setActiveWalletByType,
    setActionByDirection,
    handleExchangeFromChange,
    handleExchangeToChange,
    paymentMethods,
    action,
    form,
    diachiviBTC,
    diachiviUSDT
  }
}