import React, { useContext } from 'react'
import './Header.scss'
import { useHistory } from 'react-router-dom'
import { routes } from 'App'
import { IconArrowLeft } from 'assets/icons/iconBasic'
import { IconHomeSmile } from 'assets/icons/iconBasic'
import { IconFilter } from 'assets/icons/iconBasic'
import { IconHistory } from 'assets/icons/iconBasic'
import { IconRoundSortHorizontal } from 'assets/icons/iconBasic'
import { IconInfoCircle } from 'assets/icons/iconBasic'
import { IntlContext } from 'react-intl'
import { IconChecklist } from 'assets/icons/iconBasic'
import { IconTrashBinMinimalistic } from 'assets/icons/iconBasic'
import LanguageEnglish from 'assets/icons/iconLanguage/LanguageEnglish.png'
import LanguageCambodian from 'assets/icons/iconLanguage/LanguageCambodian.png'
import LanguageChinese from 'assets/icons/iconLanguage/LanguageChinese.png'
import LanguageKorean from 'assets/icons/iconLanguage/LanguageKorean.png'
import LanguageMalaysian from 'assets/icons/iconLanguage/LanguageMalaysian.png'
import LanguageThailand from 'assets/icons/iconLanguage/LanguageThailand.png'
import LanguageVietnamese from 'assets/icons/iconLanguage/LanguageVietnamese.png'
import LanguageIndonesia from 'assets/icons/iconLanguage/LanguageIndonesia.png'
import { Notification } from 'components/Notification'
import UserInfoBasic from 'components/Advanced/UserBasicInfo/UserInfoBasic'
import useCommonHook from 'hooks/useCommonHook'
import { useSelector } from 'react-redux'
import BasicPopover from 'components/Basic/BasicPopover'
import { formatToDisplayPrice } from 'helper/common'
import { useWallet } from 'context/WalletContext'
import { useUser } from 'context/UserContext'
import { IconDollarCoin } from 'assets/icons/iconAdvanced'
import { IconAltArrowDown } from 'assets/icons/iconBasic'
import { APP_IMAGES } from 'assets/images/AppImages'
import { useModalLoginHooks } from 'Page/LoginByModal'
import { IconQuestion } from 'assets/icons/iconBasic'
import { IconVideo } from 'assets/icons/iconBasic'
import { IconHomeCopyBet } from 'assets/icons/iconBasic'
import { ListIcon } from 'assets/icons/iconCopyBed'
import GetPointGameToPointWallet from 'components/Advanced/GetPointGameToPointWallet'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'

export function Header(props) {
  const location = useLocation()
  const { translate } = useCommonHook()
  const { wallet } = useWallet()
  const isUserLoggedIn = useSelector((state) => (state.member ? state.member.isUserLoggedIn : false))
  const { user } = useUser()
  const { setIsOpenModalLogin } = useModalLoginHooks()
  const { pathname } = useLocation();

  const {
    className,
    backgroundColor = 'secondary', // ["primary", "secondary", "danger", "success", "info", "transparent"];
    backgroundGradient = true,
    backgroundClassName = '',
    backgroundStyle = {}, // custom inline
    title, // text or component
    titleClassName = '',
    iconsLeft = 'back', //xem các loại mặc định ở dưới kia đỡ phải import
    iconsRight = [
      {
        icon: '',
        onClick: () => { }, // cách 2 {icon: "back" | <IconArrowLeft />,onClick: () => {}}, nếu muốn nhiều icon và có thể thao tác thay thế onClick cũ
        custom: undefined
      }
    ],
    iconsBackgroundColor = 'transparent', // ["primary", "secondary", "danger", "success", "info", "transparent"];
    showLogo = false,
    showProfile = false,
    backHome = false,
    showInfoUser = false
  } = props

  const history = useHistory()
  const intlContext = useContext(IntlContext)

  const headerBackground = `background_${backgroundColor || 'primary'}${backgroundGradient ? '_gradiant' : ''}`

  const iconsBackground = `background_${iconsBackgroundColor}`

  const handlePush = () => {
    if (location.state?.isRouteGoBack === true) {
      return history.goBack()
    }
    if (backHome === true) {
      history.push('/')
    } else {
      history.goBack()
    }
  }

  const icons = {
    back: {
      icon: <IconArrowLeft />,
      onClick: () => handlePush()
    },
    notification: {
      icon: <Notification />,
      onClick: () => {
        history.push(routes.notificationPersional.path)
      }
    },
    home: {
      icon: <IconHomeSmile />,
      onClick: () => {
        history.push(routes.home.path)
      }
    },
    filter: {
      icon: <IconFilter />
    },
    history: {
      icon: <IconHistory />
    },
    sortHorizontal: {
      icon: <IconRoundSortHorizontal />
    },
    info: {
      icon: <IconInfoCircle />
    },
    checkList: {
      icon: <IconChecklist />
    },
    delete: {
      icon: <IconTrashBinMinimalistic />
    },
    login: {
      icon: <span>{translate}</span>,
    },
    question: {
      icon: <IconQuestion />
    },
    video: {
      icon: <IconVideo />
    },
    listIcon: {
      icon: <ListIcon />
    },
    homeCopyBet: {
      icon: <IconHomeCopyBet />,
      onClick: () => {
        history.push(routes.home.path)
      }
    },
  }
  const RenderIcons = ({ iconsSelected }) => {
    const list = Array.isArray(iconsSelected) ? iconsSelected : typeof iconsSelected === 'object' && iconsSelected !== null ? [iconsSelected] : null

    if (list?.length > 0) {
      return list.map((item, i) => {
        if (icons?.[item?.icon]?.icon || item?.icon) {
          return (
            <div
              key={`RenderIcons_${i}`}
              className={`headerMain_icon ${iconsBackground}`}
              onClick={() => {
                const defaultOnClick = icons?.[item?.icon]?.onClick
                const newOnClick = item?.onClick
                if (newOnClick) {
                  newOnClick()
                } else {
                  if (defaultOnClick) {
                    defaultOnClick()
                  }
                }
              }}>
              {icons?.[item?.icon]?.custom || item?.custom}
              {icons?.[item?.icon]?.icon || item?.icon}
            </div>
          )
        }
      })
    } else {
      if (icons?.[iconsSelected]?.icon) {
        return (
          <div className={`headerMain_icon ${iconsBackground}`} onClick={icons?.[iconsSelected]?.onClick}>
            {icons?.[iconsSelected]?.custom}
            {icons?.[iconsSelected]?.icon}
          </div>
        )
      }
    }
    return <></>
  }

  const iconsLanguage = {
    km: <img src={LanguageCambodian} height={20} width={20} alt='' />,
    cn: <img src={LanguageChinese} height={20} width={20} alt='' />,
    en: <img src={LanguageEnglish} height={20} width={20} alt='' />,
    ko: <img src={LanguageKorean} height={20} width={20} alt='' />,
    ms: <img src={LanguageMalaysian} height={20} width={20} alt='' />,
    th: <img src={LanguageThailand} height={20} width={20} alt='' />,
    vi: <img src={LanguageVietnamese} height={20} width={20} alt='' />,
    in: <img src={LanguageIndonesia} height={20} width={20} alt='' />
  }

  return (
    <header id="header" className={`max_width_screen_project ${className}`}>
      <div className={`max_width_screen_project headerBackground ${headerBackground} ${backgroundClassName}`} style={{ ...backgroundStyle }} />
      <div className={`max_width_screen_project headerBackgroundImage`} alt='' style={{ backgroundImage: `url(${APP_IMAGES?.BackgroundCard})` }} />
      <div className={`headerMain`}>
        <div className={`headerMain_left ${pathname === routes.home.path ? "headerMain_home_left" : ""}`}>
          {showLogo && (
            <div className="headerMain_logo">
              <img src='/logo.png' alt='' />
            </div>
          )}
          {showProfile && <UserInfoBasic />}
          <RenderIcons iconsSelected={iconsLeft} />
        </div>
        <div className={`headerMain_center ${titleClassName}`}>{title}</div>
        <div className={`headerMain_right ${!isUserLoggedIn && showInfoUser ? "headerMain_no_login" : ""} ${pathname === routes.home.path ? "headerMain_home_right" : ""}`}>
          {
            isUserLoggedIn
              ?
              (<div className='center-vertical headerMain_right_wrap'>
                {
                  showInfoUser &&
                  <div className='headerMain_infoUser'>
                    <span className='headerMain_infoUser_name text-truncate'>{user?.username}</span>
                    <GetPointGameToPointWallet />
                    <BasicPopover content={
                      <table className='headerMain_wallet'>
                        <tbody>
                          <tr>
                            <td className='headerMain_wallet_title'>{translate('Point_wallet')}</td>
                            <td className='headerMain_wallet_value'>{formatToDisplayPrice(wallet.pointBalance)}</td>
                          </tr>
                          <tr>
                            <td className='headerMain_wallet_title'>{translate('Escrow_wallet')}</td>
                            <td className='headerMain_wallet_value'>{formatToDisplayPrice(wallet.expertFundBalance)}</td>
                          </tr>
                          <tr>
                            <td className='headerMain_wallet_title'>{translate('Total_balance')}</td>
                            <td className='headerMain_wallet_value'>{formatToDisplayPrice((wallet.pointBalance + wallet.expertFundBalance))}</td>
                          </tr>
                        </tbody>
                      </table>
                    }
                      placement="bottomRight"
                      overlayClassName="headerMain_infoUser_popover"
                    >
                      <div className='center-vertical'>
                        <p className='headerMain_infoUser_balance'>{formatToDisplayPrice((wallet.pointBalance + 0))}</p>
                        <IconAltArrowDown />
                      </div>
                    </BasicPopover>
                  </div>
                }
                <RenderIcons iconsSelected={iconsRight} />
              </div>)
              :
              (showInfoUser &&
                <div className='center-vertical w-100'>
                  <div className="headerMain_language" onClick={() => history.push(routes.settingLanguage.path)}>
                    {iconsLanguage?.[intlContext?.locale]}
                  </div>
                  <div className='headerMain_showInfoUser'>
                  <span
                      className='headerMain_button background_success_gradiant'
                      onClick={() => history.push(routes.register.path)}
                    >
                      {translate('register')}
                    </span>
                    <span
                      className='headerMain_button background_primary_gradiant'
                      onClick={() => setIsOpenModalLogin(true)}
                    >
                      {translate('login')}
                    </span>
                  </div>
                </div>)
          }
        </div>

      </div>
    </header>
  )
}
