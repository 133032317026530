import React, { useEffect } from 'react'
import './index.scss'
import useCommonHook from 'hooks/useCommonHook'
import { APP_IMAGES } from 'assets/images/AppImages'
import { checkUrlIsImageOrVideo } from 'helper/common'
import useReloadResource from '../../actionsFeatures/actionsLoadResource'

export function Splash(props) {
  const { translate } = useCommonHook()
  const { loadGameCategoryAndGameInfo } = useReloadResource()

  useEffect(() => {
    handlePreloadData()
    return () => { }
  }, [])

  async function handlePreloadData() {
    preloadStaticImage()
    await loadGameCategoryAndGameInfo()
    setTimeout(() => {
      props?.setIsLoading && props.setIsLoading(false)
    }, 100)
  }

  async function loadResources(url) {
    if (checkUrlIsImageOrVideo(url) === 'video') {
      const video = document.createElement('video');
      video.src = url;
      video.onloadedmetadata = () => { }
    } else {
      const image = new Image();
      image.src = url;
      image.onload = () => { }
    }
  }

  async function preloadStaticImage() {
    const images = Object.values(APP_IMAGES)
    for (const image of images) {
      loadResources(image)
    }
  }

  return (
    <div className="splash max_width_screen_project">
      <div className="splashContent">
        <img src="/logoWithText.png" alt="" height={40} />
        <p>{translate('LOADING_TEXT')}</p>
      </div>
    </div>
  )
}
