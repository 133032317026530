import { handleSetListCategory } from "actions"
import { handleSetListGame } from "actions"
import { useDispatch } from "react-redux"
import GameService from "services/gameService"

export default function useReloadResource() {
  const dispatch = useDispatch()
  async function loadGameCategoryAndGameInfo() {
    const LIMIT = 5
    const paramsFilter = {}
    let arrayData = []
    async function handleFetchData() {
      const data = await gameInfoFind(paramsFilter)
      if (!data) return
      if (Array.isArray(data)) {
        arrayData = [...arrayData, ...data]
        paramsFilter.skip += LIMIT
        if (data.length === LIMIT) {
          return await handleFetchData()
        }
      }
    }

    await gameCategoryFind()
    await handleFetchData()

    dispatch(handleSetListGame(arrayData.sort((a, b) => (a.gameOrdinal == null) - (b.gameOrdinal == null) || (a.gameOrdinal - b.gameOrdinal))))
  }

  async function gameCategoryFind() {
    await GameService.GameCategoryFind({}).then((result) => {
      if (result.isSuccess) {
        dispatch(handleSetListCategory(result.data?.data?.sort((a, b) => (a.gameCategoryOrdinal == null) - (b.gameCategoryOrdinal == null) || (a.gameCategoryOrdinal - b.gameCategoryOrdinal))))
      }
    })
  }

  async function gameInfoFind(params) {
    const data = await GameService.GameInfoFind(params).then((result) => {
      if (result.isSuccess) {
        return result.data?.data
      } else {
        return undefined
      }
    })
    return data
  }

  return {
    loadGameCategoryAndGameInfo
  }
}