import { combineReducers } from 'redux'
import app from './app'
import member from './member'
import gameInfo from './gameInfo'

const rootReducer = combineReducers({
  member,
  app,
  gameInfo
})

export default rootReducer
